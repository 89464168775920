<template>
  <div class="info">
    <ul>
      <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/engineicon_rivit.png" alt="">
          </div>
          <p>WormHole Exporter For Revit</p>
        </div>
        <div class="info">WormHole Exporter For Revit简介Revit插件用于BlackHole三维图形引擎的前端数据转换，支持从Autodesk Revit软件自动导出Revit模型数据到本磁盘。插件支持导出的Revit模...数据包括：构件几何信息、构件属...</div>
      </li>
       <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/engineicon_ifc.png" alt="">
          </div>
          <p>WormHole Exporter For IFC</p>
        </div>
        <div class="info">WormHole Exporter For IFC简介IFC插件用于BlackHole图形引擎的前端数据转换，支持IFC（工业基础类）格式文件进行自动读取和解析，并将解析后的建筑信息模型几何和属性信息完整转换成自...</div>
      </li>
      <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/engineicon_bentley.png" alt="">
          </div>
          <p>WormHole Exporter For Bentley</p>
        </div>
        <div class="info">WormHole Exporter For Bentley简介 Bentley插件用于BlackHole图形引擎的前端数据转换，支持用户从MicroStation CONNECT Edition...</div>
      </li>
       <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/engineicon_max.png" alt="">
          </div>
          <p>WormHole Exporter For 3Ds Max</p>
        </div>
        <div class="info">WormHole Exporter For IFC简介 3Ds Max插件用于BlackHole三维图形引擎的前端数据转换，支持从Autodesk 3ds Max软件自动导出3dsmax模型数到本地磁盘…...</div>
      </li>
      <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/engineicon_blackhole.png" alt="">
          </div>
          <p style="line-height:34px;">新一代BIM+GIS渲染引擎——BlackHole Engine</p>
        </div>
        <div class="info">BlackHole Engine 简介 BlackHole Engine是国内领先的具有自主知识产权的BIM+GIS轻量化渲染引擎。采用全新的三维渲染技术，可轻松管理多类型、大...</div>
      </li>
       <li>
        <div class="header">
          <div class="pic">
            <img src="../../assets/image/product/engineicon_edit.png" alt="">
          </div>
          <p>WormHole Editor</p>
        </div>
        <div class="info">WormHole Editor（虫洞编辑器）是由上海秉匠信息科技有限公司自主研发的产品，配合BlackHole Engine使用的场景编辑器，负责模型数据...</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.info{
  width: 100%;
  ul{
    max-width: 1200px;
    margin: 0px auto 146px;
    // background-color: pink;
    overflow: hidden;
    padding: 10px;
    li{
      width: 31%;
      // background-color: blue;
      margin-right: 3%;
      float: left;
      margin-top: 80px;
      padding: 10px;
      border-radius:3px;
      box-shadow: -1px 0 10px rgba(0, 0, 0, 0.05), 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 10px rgba(0, 0, 0, 0.05), 1px 0 10px rgba(0, 0, 0, 0.05);
      .header{
        overflow: hidden;
        .pic{
          width: 68px;
          height: 68px;
          float: left;
          border: 1px dashed #ccc;
          img{
            width: 100%;
            height: 100%;
          }
        }
        p{
          float: left;
          line-height: 68px;
          margin-left: 10px;
          display: block;
          width: calc(100% - 78px);
          // word-wrap: break-word;
          // word-break: break-all;
          // overflow: hidden;
        }
      }
      .info{
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    li:nth-child(3n+3){
      margin-right: 0%;
    }
  }
}
</style>
